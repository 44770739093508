<template>
  <div>
    <v-card>
      <v-card-title>
        <span>หมวดหมู่สินค้า</span>
        <v-spacer></v-spacer>
        <v-btn color='primary' @click='isAddNewsProductCategories  = true'>เพิ่มหมวดหมู่</v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getCategories'
                        label='ค้นหาหมวดหมู่'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <FilterStatus @selection='changeStatus' />
        </v-col>
      </v-row>
      <v-data-table
        :headers='headers'
        :items='dataList'
        :items-per-page='itemsPerPage'
        :loading='loading'
        no-data-text='ไม่มีข้อมูล !'
        loading-text='กำลังโหลดข้อมูล...'
        :footer-props='footer'
        no-results-text='ไม่มีข้อมูล !'
        class='elevation-1'
      >
        <template #[`item.product_type_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.product_type_active`]='{ item }'>
          <StatusBlock :status='String(item.product_type_active)' />
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                icon
                v-bind='attrs'
                @click='dataEdit = item;isEdit = true'
                v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                icon
                v-bind='attrs'
                @click='dataEdit = item;isUpdateStatus = true'
                v-on='on'
              >
                <v-icon>{{ item.product_type_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.product_type_active == 1 ? `ระงับ` : `ปกติ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewProductCategories v-model='isAddNewsProductCategories' @onAdd='getCategories' />
    <EditProductCategories v-model='isEdit' :data-edit='dataEdit' @onUpdate='getCategories' />
    <SuspendProductCategoires v-model='isUpdateStatus' :data-update='dataEdit'
                              @onUpdate='getCategories' />
  </div>
</template>

<script>
import FilterStatus from '@/components/FilterStatus'
import useProductCategories from './useProductCategories'
import { mdiPencilOutline, mdiDeleteOutline, mdiCheck } from '@mdi/js'
import StatusBlock from '@/components/StatusBlock'
import AddNewProductCategories from './AddNewProductCategories'
import EditProductCategories from '@/views/center/productCategories/EditProductCategories'
import SuspendProductCategoires from '@/views/center/productCategories/SuspendProductCategoires'

export default {
  components: {
    SuspendProductCategoires,
    EditProductCategories,
    AddNewProductCategories,
    FilterStatus,
    StatusBlock,
  },
  setup(_, { root, emit }) {
    return {
      ...useProductCategories(),
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiCheck,
    }
  },

}
</script>

<style scoped>

</style>

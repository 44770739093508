<template>
  <div>
    <v-dialog v-model='isAddNewsProductCategories' persistent max-width='500'>
      <v-card>
        <v-card-title>
          เพิ่มหมวดหมู่สินค้า
        </v-card-title>
        <v-form ref='formAddNewsProductCategories' @submit.prevent='createProductCategory'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='category_name' filled dense
                            label='ชื่อหมวดหมู่สินค้า'
                            placeholder='ชื่อหมวดหมู่สินค้า'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>

          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isAddNewsProductCategories`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import { api } from '@/services/api'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewsProductCategories',
    event: 'update:isAddNewsProductCategories',
  },
  props: {
    isAddNewsProductCategories: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const category_name = ref('')
    const loading = ref(false)
    const formAddNewsProductCategories = ref(null)

    const createProductCategory = () => {
      const isFormValid = formAddNewsProductCategories.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.post({
        path: '/center/productType',
        body: {
          product_type_name: category_name.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewsProductCategories', false)
          emit('onAdd')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isAddNewsProductCategories, value => {
      if (value) {
        loading.value = false
        category_name.value = ''
      }
    })

    return {
      formAddNewsProductCategories,
      loading,
      category_name,
      required,
      createProductCategory,
    }
  },

}
</script>

<style scoped>

</style>

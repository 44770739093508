import { api } from '@/services/api'
import { ref, onMounted } from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables'

export default function useProductCategories() {

  const textSearch = ref('')
  const headers = ref([
    {
      text: '#',
      value: 'product_type_id',
      width: '50',
    },
    {
      text: 'ชื่อร้านค้า',
      value: 'product_type_name',
      width: '200',
    },
    {
      text: 'สถานะ',
      value: 'product_type_active',
      width: '100',
    },
    {
      text: 'ตัวเลือก',
      value: 'actions',
      width: '200',
      align: 'center',
    },
  ])
  const itemsPerPage = ref(10)
  const status = ref('1')
  const dataList = ref([])
  const loading = ref(false)
  const isUpdateStatus = ref(false)
  const dataEdit = ref({})
  const footer = ref(footerDataTables)
  const isEdit = ref(false)
  const isAddNewsProductCategories = ref(false)

  const changeStatus = (id) => {
    status.value = id
    getCategories()
  }

  const getCategories = () => {
    loading.value = true
    api.get({
      path: '/center/productTypes',
      param: `?search=${textSearch.value}&active=${status.value}`,
    }).then(res => {
      dataList.value = res.data
      loading.value = false
    })
  }

  onMounted(() => {
    getCategories()
  })

  return {
    textSearch,
    headers,
    itemsPerPage,
    isAddNewsProductCategories,
    status,
    dataList,
    dataEdit,
    footer,
    isEdit,
    loading,
    isUpdateStatus,
    changeStatus,
    getCategories,

  }
}

<template>
  <div>
    <v-dialog v-model='editNewsProductCategories' persistent max-width='500'>
      <v-card>
        <v-card-title>
          แก้ไขหมวดหมู่สินค้า
        </v-card-title>
        <v-form ref='formEditProductCategories' @submit.prevent='updateProductCategory'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='dataEditLocal.product_type_name' filled dense
                            label='ชื่อหมวดหมู่สินค้า'
                            placeholder='ชื่อหมวดหมู่สินค้า'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>

          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:editNewsProductCategories`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import { api } from '@/services/api'
import store from '@/store'

export default {
  model: {
    prop: 'editNewsProductCategories',
    event: 'update:editNewsProductCategories',
  },
  props: {
    editNewsProductCategories: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { root, emit }) {
    const loading = ref(false)
    const formEditProductCategories = ref(null)
    const dataEditLocal = ref({})

    const updateProductCategory = () => {
      const isFormValid = formEditProductCategories.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.put({
        path: '/center/productType',
        param: dataEditLocal.value.product_type_id,
        body: {
          product_type_name: dataEditLocal.value.product_type_name,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:editNewsProductCategories', false)
          emit('onUpdate')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.editNewsProductCategories, value => {
      if (value) {
        loading.value = false
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
      }
    })

    return {
      formEditProductCategories,
      loading,
      required,
      dataEditLocal,
      updateProductCategory,
    }
  },

}
</script>

<style scoped>

</style>
